import request from '@/network/request.js'
/**
 * 获取测试分类列表
 */
export function lists_make_last(data) {
	return request({
		url: '/admin/make_last/lists_make_last',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改类型分类
 */
export function creat_up_make_last(data) {
	return request({
		url: '/admin/make_last/creat_up_make_last',
		method: 'post',
		data,
	})
}

/**
 * 删除|批量类型分类
 */
export function dels_make_last(data) {
	return request({
		url: '/admin/make_last/dels_make_last',
		method: 'post',
		data,
	})
}



